
@import "~bootstrap/scss/functions";

$blue:    #4c6a92;
$red:     #dc4c46;
$pink:    #e83e8c;
$orange:  #ee944e;
$yellow:  #ffc40d;
$green:   #9abc59;
$cyan:    #5eabc4;
$purple:  #7f60a1;
$navy: #546778;
$gray-100: #f7f8f9;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-600: #6c757d;
$gray-900: #212529;

$custom-colors: (
  "primary":       $blue,
  "secondary":       $navy,
  "warning":     $yellow,
  "info":       $cyan,
  "success":       $green,
  "danger":       $red,
  "navy":        $navy,
  "agenda":     $orange,
  "resultats":  $green,
  "photos":     $purple,
  "challenges": $cyan,
  "membres":    $navy,
  "contact":    $gray-600,
  "dark":       $gray-900,
  "light":    $gray-100,
    "blue":         $blue,
    "blue-light":   lighten($blue, 50%),
    "blue-dark":    darken($blue, 40%),
    "blue-75":      lighten($blue, 25%),
    "cyan":         $cyan,
    "green":        $green,
    "green-light":  lighten($green, 35%),
    "green-dark":   darken($green, 25%),
    "red":          $red,
    "red-light":    lighten($red, 30%),
    "orange":       $orange,
    "pink":         $pink,
    "pink-light":   lighten($pink, 25%),
    "purple":       $purple,
    "yellow":       $yellow,
    "yellow-light": lighten($yellow, 35%),
    "yellow-dark":  darken($yellow, 10%),
    "gray":         $gray-600,
    "gray-light":   $gray-200,
    "gray-medium":  $gray-400,
  );


// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";

// 4. Include any default map overrides here
$theme-colors: map-merge($theme-colors, $custom-colors);


// 5. Include remainder of required parts
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";

// 6. Optionally include any other parts as needed

@import "~bootstrap/scss/utilities";


$utilities: map-merge(
  $utilities,
  (
    "width": map-merge(
      map-get($utilities, "width"),
      (
        values: map-merge(
          map-get(map-get($utilities, "width"), "values"),
          (1: 1%, 2: 2%, 3: 3%, 4: 4%, 5: 5%,
          7: 7%, 8: 8%, 10: 10%,
          12: 12%, 15: 15%,
          20: 20%, 25: 25%,
          30: 30%, 40: 40%, 45: 45%,
          50: 50%, 60: 60%, 65: 65%,
          70: 70%, 75: 75%,
          80: 80%, 90: 90%),
        ),
      ),
    ),
  )
);
$utilities: map-merge(
  $utilities,
  (
    "height": map-merge(
      map-get($utilities, "height"),
      (
        values: map-merge(
          map-get(map-get($utilities, "height"), "values"),
          (5: 5%,
          10: 10%,
          15: 15%,
          20: 20%, 25: 25%,
          30: 30%,
          40: 40%, 45: 45%,
          50: 50%,
          60: 60%, 65: 65%,
          70: 70%, 75: 75%,
          80: 80%),
        ),
      ),
    ),
  )
);

$h7-font-size: $font-size-base * 0.75;


$utilities: map-merge(
        $utilities,
        (
            "font-size": map-merge(
                    map-get($utilities, "font-size"),
                    (
                        values: map-merge(
                                map-get(map-get($utilities, "font-size"), "values"),
                                (7: $h7-font-size),
                        ),
                    ),
            ),
        )
);


$utilities: map-merge(
  $utilities,
  (
  "background-color": (
      property: background-color,
      class: bg,
      state: hover focus,
      values: $theme-colors
  )
  )
);

$utilities: map-merge(
  $utilities,
  (
  "color": (
      property: color,
      class: text,
      state: hover focus,
      values: $theme-colors
  )
  )
);


@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/offcanvas";

@import "~bootstrap/scss/helpers";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "~bootstrap/scss/utilities/api";

// 8. Add additional custom code here



.mollie-component {
  @extend .form-control;
}
.note-editor.note-frame {
  @extend .form-control;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: scroll;
  max-height: calc(80vh - var(--bs-modal-margin)*2);
}